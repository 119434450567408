.ns-multi-upload {
  background-color: transparent;
  margin: 0;
  border: none;

  button {
    border-radius: 0;
    border: 1px solid rgba(0, 0, 0, 0.12);
  }

  .required-label-outside {
    color: red;
  }

  .uploadArea {
    margin-bottom: 15px;

    input[type="file"] {
      display: none;
    }

    mat-card {
      background-color: transparent;
      border: none;
      height: 100%;
      margin: 0;
      padding: 0;
      box-shadow: none !important;

      mat-card-actions {
        height: 100%;
        padding: 0;
        margin: 0;

        button {
          height: 100%;
          width: 100%;
        }

        mat-label {
          word-break: break-word;
          margin-bottom: 20px;
          max-width: 128px;
          display: block;
        }
      }
    }

    .upload-button {
      background-color: transparent;
      border: 1px dashed #3366FF;
      border-radius: 10px;
      width: 150px;
      height: 150px;
      float: left;
      min-height: 45px;
      margin-bottom: 10px;
      padding: 10px;

      .mat-button-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        place-content: center;
        align-items: center;

        .preview-image {
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          width: 100%;
          height: 100%;
          border-radius: 10px;
          z-index: 1;
        }
      }

      mat-icon {
        color: #3366FF;
      }

      mat-label {
        display: block;
        width: 150px;
      }

      .fa-plus {
        color: #3366FF;
      }

      .fa-file-alt {
        position: absolute;
        top: 20px;
        left: 20px;
        color: rgba(51, 102, 255, 0.4);
        font-size: 90px;
        font-weight: 100;
      }

      .fa-eye {
        position: absolute;
        bottom: 5px;
        left: 5px;
        color: #3366ff;
        font-size: 16px;
        font-weight: bold;
        background-color: #FFF0EF;
        border: 1px solid #E6F2FC;
        border-radius: 50%;
        height: 32px;
        width: 32px;
        display: flex;
        place-content: center;
        align-items: center;
        z-index: 2;
      }

      .fa-eye:hover {
        border: 1px solid #0088f8;
      }

      .fa-trash-alt {
        position: absolute;
        bottom: 5px;
        right: 5px;
        color: #ff837d;
        font-size: 16px;
        font-weight: bold;
        background-color: #FFF0EF;
        border: 1px solid #E6F2FC;
        border-radius: 50%;
        height: 32px;
        width: 32px;
        display: flex;
        place-content: center;
        align-items: center;
        z-index: 2;
      }

      .fa-trash-alt:hover {
        border: 1px solid #ff4036;
      }
    }
  }
}
