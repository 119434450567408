.ns-date-picker {
  width: 100%;

  .btnDatePicker {
    min-width: 33px;
    padding: 0px;
  }

  .required-label-outside{
    color: red;
  }

  mat-form-field{

  }
}
