.ns-multi-select-autocomplete {
  width: 100%;

  mat-label {
    .label {
      .required-label-outside {
        color: red;
      }
    }
  }

  mat-form-field {
    width: 100%;
    .mat-form-field-wrapper {
      .mat-form-field-flex {
        .mat-form-field-outline {
          .mat-form-field-outline-start {}
          .mat-form-field-outline-gap {}
          mat-form-field-outline-end {}
        }

        .mat-form-field-outline-thick {
          .mat-form-field-outline-start {}
          .mat-form-field-outline-gap {}
          mat-form-field-outline-end {}
        }

        .mat-form-field-infix {
          mat-select {
            .mat-select-trigger {
              .mat-select-value {
                .mat-select-placeholder {}
              }

              .mat-select-arrow-wrapper {
                .mat-select-arrow {}
              }
            }
          }

          .mat-form-field-label-wrapper {

          }
        }

        .mat-form-field-suffix {
          mat-icon {
            display: flex !important; // override .mat-icon style
            place-content: center;
            align-items: center;
          }
        }
      }
    }


  }

}

.mat-select-panel-wrap {
  .mat-select-panel {
    .box-search {
      margin: 8px;
      border-radius: 2px;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
      transition: box-shadow 200ms cubic-bezier(0.4, 0, 0.2, 1);
      display: flex;

      mat-checkbox {
        width: 36px;
        line-height: 33px;
        color: #808080;
        text-align: center;
      }

      input {
        flex: 1;
        border: none;
        outline: none;

        &.pl-1 {
          padding-left: 1rem;
        }
      }

      .box-search-icon {
        button {
          width: 36px;
          height: 36px;
          line-height: 33px;
          color: #808080;
        }
      }
    }

    mat-option {

      h4 {
        font-weight: 100;
      }

      mat-pseudo-checkbox {}

      span {
        overflow: initial;

        > div {
          line-height: 0rem;

          p {
            font-size: smaller;
            margin: 0;
          }
        }
      }

      &.selected-options {
        visibility: hidden;
        position: absolute;
      }
    }
  }
}

.tree-multiline-mat-option .mat-line {
  margin-bottom: 20px;
}